import {
  BeautyFitness,
  Cleaning,
  Construction,
  CustomerServices,
  Education,
  EventsPromotion,
  FoodBeverage,
  HealthServices,
  HotelClubHouse,
  LogisticsTransport,
  Maintenance,
  Office,
  ProductionPackaging,
  PropertyMgtSecurity,
  RetailShop,
  SalesAgents,
} from '../libs/mapping/jobType'
import { FullTime, PartTime, Temp } from '../libs/mapping/employmentType'
import { NEARBY_L_KEY } from '../libs/mapping/nearby'

export default {
  computed: {
    /**
     * Classes for input component
     */
    mobileJobCateItems() {
      return [
        NEARBY_L_KEY,
        RetailShop,
        PartTime,
        FoodBeverage,
        FullTime,
        EventsPromotion,
        Temp,
        Education,
        Office,
        PropertyMgtSecurity,
        CustomerServices,
        LogisticsTransport,
        HotelClubHouse,
        BeautyFitness,
        Maintenance,
        HealthServices,
        SalesAgents,
        Construction,
        ProductionPackaging,
        Cleaning,
      ]
    },
  },
}
