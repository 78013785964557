<template>
  <div class="home-side-meun">
    <h3 class="home-side-menu__title">{{ $t('general.allCategory') }}</h3>
    <template v-for="key in meunItems">
      <home-side-menu-item
        :key="`CategoryButton-${key}`"
        :category-key="key"
        class="home-side-meun__item"
        :event-source="eventSource"
      />
    </template>
  </div>
</template>

<script>
import { jobCategoriesSortOrder, Others } from '../../libs/mapping/jobType'
import { employmentTypes } from '../../libs/mapping/employmentType'
import HomeSideMenuItem from './homeSideMenuItem'
import { NEARBY_L_KEY } from '../../libs/mapping/nearby'

const MENU_ITEMS = [
  NEARBY_L_KEY,
  ...employmentTypes.map(({ name }) => name),
  ...jobCategoriesSortOrder.filter((category) => category !== Others),
]

export default {
  components: {
    'home-side-menu-item': HomeSideMenuItem,
  },
  data() {
    return {
      meunItems: MENU_ITEMS,
    }
  },
  props: {
    /**
     * Used for event tracking
     */
    eventSource: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.home-side-meun {
  @apply px-2 py-4 rounded-xl;
  @apply bg-white;
  width: 240px;
}
.home-side-menu__title {
  @apply text-csub-title1 font-csub-title1 ml-5 mb-2;
}
</style>
