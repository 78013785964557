<template>
  <section class="list">
    <div class="list__header">
      <h2 v-if="title" class="list__header-title">{{ title }}</h2>
    </div>
    <div class="list__jobs">
      <template v-for="(company, i) in companies">
        <div
          v-if="company && company.company_id"
          :key="`HorizontalListFeaturedCompany${i}-${company.company_id}`"
          class="list__job"
        >
          <company-card :company="company" source="homepage" :position="i" />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import CompanyCard from '@/components/job/jobCard/companyCard'

export default {
  components: {
    'company-card': CompanyCard,
  },
  computed: {
    allCompaniesPath() {
      return {
        path: this.localePathName('/featured-companies'),
      }
    },
  },
  props: {
    companies: {
      required: true,
      type: Array,
    },
    source: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.list__header {
  @apply flex justify-center items-center mb-1;
}
.list__header-title {
  @apply flex-grow;
}

.list__jobs {
  @apply flex flex-wrap;
}
.list__job {
  @apply w-1/4 p-1;
}
@screen mobile {
  h2 {
    @apply mb-2 text-xl;
  }
  .list__job {
    @apply w-1/2;
  }
}
</style>
