<template>
  <a
    :id="id"
    :href="banner.link"
    class="banner__item"
    title="Banner"
    target="_blank"
    rel="noopener"
    @click="onBannerClick"
  >
    <picture>
      <source :srcset="banner.cover_image + '_webp'" sizes="720px" height="377" width="720" type="image/webp" />
      <source :srcset="banner.cover_image" sizes="720px" height="377" width="720" />
      <img
        class="banner__img"
        :alt="banner.description || 'Banner image'"
        :title="banner.description || 'Banner image'"
        height="377"
        width="720"
        :src="banner.cover_image"
      />
    </picture>
  </a>
</template>

<script>
import BannerMixin from '../../mixins/tracking/banner'
import { sendHomeBannerClickEvent } from '../../libs/tracking/utils/event'

export default {
  methods: {
    onBannerClick() {
      sendHomeBannerClickEvent(this.$eventTracker, {
        imageUrl: this.banner.cover_image,
        link: this.banner.link,
        position: this.position,
        sessionId: this.sessionId,
        source: this.source,
      })
    },
  },
  mixins: [BannerMixin],
  props: {
    banner: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.banner__item {
  @apply flex-shrink-0 w-full h-full;
}
.banner__img {
  @apply w-full h-full rounded-lg;
}
</style>
