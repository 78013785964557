import ImpressionListItemMixin from './impressionListItem'
import { sendHomeBannerImpressionEvent } from '../../libs/tracking/utils/event'

export default {
  methods: {
    onImpressionEvent() {
      sendHomeBannerImpressionEvent(this.$eventTracker, {
        imageUrl: this.banner.cover_image,
        link: this.banner.link,
        position: this.position,
        sessionId: this.sessionId,
        source: this.source,
      })
    },
  },
  mixins: [ImpressionListItemMixin],
}
