<template>
  <section class="list list--rec">
    <h2>{{ $t('homepage.recList') }}</h2>
    <div class="list__jobs">
      <template v-for="(job, i) in jobs">
        <div v-if="job && job._id" :key="`RecommendedListJob-${job._id}`" class="list__job">
          <job-card
            :company="job.company"
            :job="job"
            :observer="observerObj"
            :is-interact="isInteraction(job._id)"
            :tracking-params="trackingParams"
            :session-id="sessionId"
            :source="source"
            :position="i"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import ListMixin from '../../mixins/list'

export default {
  mixins: [ListMixin],
}
</script>

<style lang="postcss" scoped>
@screen mobile {
  h2 {
    @apply mb-2 text-xl font-bold;
  }

  .list__job {
    @apply w-full;
  }
  >>> .jc {
    @apply mx-auto;
  }
}
</style>
