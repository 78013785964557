<template>
  <main class="home container">
    <!-- Start of top reminder bar-->
    <!-- <div class="announcement">{{ $t('announcement.iosIssue') }}</div>-->
    <!-- End of top reminder bar-->
    <!--    Start of popup reminder -->
    <!--    <popup-form v-if="isShowReminder"-->
    <!--                @close="closeReminder">-->
    <!--      <template #main>-->
    <!--        <img :src="fraudReminderImgSource" alt="fraud job invitation reminder" class="popup__reminder">-->
    <!--      </template>-->
    <!--    </popup-form>-->
    <!--    End of popup reminder-->
    <meta-home :job-count="jobCount" />
    <div class="home__side_section">
      <home-side-menu event-source="home.category" />
    </div>
    <div class="home__main">
      <home-banner v-if="banners.length" class="home__banners" :items="banners" :session-id="session.banners" />
      <home-job-cate-menu event-source="home.category" />
      <div v-if="featuredLists.length" class="home__featured-lists">
        <template v-for="list in featuredLists">
          <featured-list
            :id="list._id"
            :key="`HomeFeaturedList-${list._id}`"
            source="home.feature_list"
            :jobs="list.jobs"
            :session-id="session.featuredLists[list._id]"
            :filter-query-object="getFilterQueryObjectFromData(list.data)"
            :title-locales="list.name"
          />
        </template>
      </div>
      <rec-list
        v-if="recommendedList"
        class="home__related-lists"
        source="home.related_job"
        :jobs="recommendedList.jobs"
        :session-id="session.recommendedList"
      />
      <horizontal-featured-company-list
        v-if="featuredCompanyList.length"
        class="home__feature-company-lists"
        source="homepage"
        :companies="featuredCompanyList"
        :title="featuredCompanyListTitle"
      />
    </div>
  </main>
</template>

<script>
import { createSessionId } from '../libs/tracking/utils/session'
import FeaturedList from '../components/list/featuredList'
import { GET_HOMEPAGE_FULL_QUERY } from '../api/octo/graphql/queries'
import { getFeaturedCompanyListTitle } from '@/libs/featuredCompanyList'
import GraphqlFormMixin from '../mixins/form/graphql'
import HomeBanner from '../components/banner/HomeBanner'
import HomeJobCateMenu from '../components/home/homeJobCateMenu'
import HomeMetaData from '../components/home/homeMetaData'
import HomeSideMenu from '../components/home/homeSideMenu'
import HorizontalFeaturedCompanyList from '@/components/list/horizontalFeaturedCompanyList'
// import PopupForm from '../components/popup/popupForm'
import RecommendedList from '../components/list/recommendedList'
import { transformSearchQueryToFilterQueryObject } from '../libs/search/filter'

export default {
  asyncData({ payload = {} }) {
    // Restore banners and featured lists if available
    const { banners, featuredLists, jobCount, featuredCompanyList, featuredCompanyListLocale } = payload
    if (Array.isArray(banners) && Array.isArray(featuredLists) && Array.isArray(featuredCompanyList)) {
      return { banners, featuredCompanyList, featuredCompanyListLocale, featuredLists, jobCount }
    }
  },
  components: {
    'featured-list': FeaturedList,
    'home-banner': HomeBanner,
    'home-job-cate-menu': HomeJobCateMenu,
    'home-side-menu': HomeSideMenu,
    'horizontal-featured-company-list': HorizontalFeaturedCompanyList,
    'meta-home': HomeMetaData,
    // 'popup-form': PopupForm,
    'rec-list': RecommendedList,
  },
  computed: {
    featuredCompanyListTitle() {
      return getFeaturedCompanyListTitle(this.featuredCompanyListLocale, this.$i18n.locale)
    },
    // fraudReminderImgSource () {
    //   return require(`../assets/fraud-reminder-${this.currentLocale.code}.webp`)
    // },
  },
  created() {
    // On client side
    if (process.client) {
      // Create session IDs
      this.createSessionIDsIfEmpty()

      // Fetch homepage data once token ready
      this.$tokenReady(this.getHomepage)
    }
  },
  data() {
    return {
      banners: [],
      featuredCompanyList: [],
      featuredCompanyListLocale: [],
      featuredLists: [],
      /**
       * Graphql queries
       */
      gqlQueries: {
        homePageQuery: GET_HOMEPAGE_FULL_QUERY,
      },
      /**
       * Avoid to load twice
       */
      isLoading: false,
      // isShowReminder: false,
      jobCount: null,
      recommendedList: null,
      session: {
        banners: '',
        featuredLists: {},
        recommendedList: '',
      },
    }
  },
  head() {
    const url = `${process.env.SEEKER_WEB__BASE_URL}/`
    return {
      /**
       * Add *Sitelinks search box* to Google search result,
       * ref: https://developers.google.com/search/docs/data-types/sitelinks-searchbox
       */
      script: [
        {
          hid: 'website',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            potentialAction: {
              '@type': 'SearchAction',
              'query-input': 'required name=search_term_string',
              target: `${url}search/?q={search_term_string}`,
            },
            url,
          },
          type: 'application/ld+json',
        },
      ],
    }
  },
  methods: {
    // closeReminder () {
    //   this.isShowReminder = false
    // },
    /**
     * Create IDs for session(s) w/o ID yet
     */
    createSessionIDsIfEmpty() {
      if (!this.session.recommendedList) {
        this.session.recommendedList = createSessionId()
      }
      if (!this.session.banners) {
        this.session.banners = createSessionId()
      }
      this.featuredLists.forEach((list) => {
        if (!this.session.featuredLists[list._id]) {
          this.session.featuredLists[list._id] = createSessionId()
        }
      })
    },

    getFilterQueryObjectFromData(data) {
      if (data && data.search_query) {
        return transformSearchQueryToFilterQueryObject(data.search_query)
      }
    },

    /**
     * Handle fetching homepage data from backend
     * and updating existing data from server-side generation
     * @returns {Promise<void>}
     */
    async getHomepage() {
      if (!this.isLoading) {
        this.isLoading = true
        const res = await this.submitGql('homePageQuery', true)
        this.isLoading = false
        if (res && res.data && res.data.homepage) {
          const homepage = res.data.homepage
          // Replace featured lists
          // w/ fetched non-empty lists
          if (Array.isArray(homepage.job_featured_lists)) {
            this.featuredLists = homepage.job_featured_lists.filter(
              (list) => list && list._id && Array.isArray(list.jobs) && list.jobs.length,
            )
          }

          // Set recommended list if not empty
          if (
            homepage.job_recommended_list &&
            Array.isArray(homepage.job_recommended_list.jobs) &&
            homepage.job_recommended_list.jobs.length
          ) {
            this.recommendedList = homepage.job_recommended_list
          }

          if (
            homepage.featured_companies &&
            Array.isArray(homepage.featured_companies.companies) &&
            homepage.featured_companies.companies.length
          ) {
            this.featuredCompanyList = homepage.featured_companies.companies
            this.featuredCompanyListLocale = homepage.featured_companies.name
          }

          // Set banners list
          // if not available yet and not empty
          if (Array.isArray(homepage.home_banners) && homepage.home_banners.length) {
            this.banners = homepage.home_banners
          }
        }

        // Create session IDs for added featured lists
        this.createSessionIDsIfEmpty()
      }
    },
  },
  mixins: [GraphqlFormMixin],
  // mounted () {
  //   const reminderCookie = document.cookie
  //     .split('; ').find(row => row.startsWith('moovupReminder='))
  //   if (!reminderCookie) {
  //     this.isShowReminder = true
  //     document.cookie = 'moovupReminder=1; max-age=86400'
  //   }
  // },
  name: 'HomePage',
}
</script>

<style lang="postcss" scoped>
/* .announcement {
  @apply text-center absolute text-base text-white;
  z-index: 9;
  right: 0;
  left: 0;
  background: rgba(230, 103, 44, 1);
} */
.home {
  @apply flex flex-row w-full;
}
/*.home >>> .popup {*/
/*  max-height: 80vh;*/
/*}*/
/*.home >>> .popup__content {*/
/*  @apply justify-center*/
/*}*/
/*.popup__reminder {*/
/*  @apply object-contain;*/
/*}*/
.home__banners {
  @apply mb-7;
}
.home__featured-lists {
  @apply mt-2 mb-3;
}
.home__main {
  @apply min-w-0 flex-1 px-2 mx-auto;
}

.home__related-lists {
  @apply mb-11;
}

@screen mobile {
  /* .announcement {
    @apply text-sm;
  } */
  /* .home__banners {
    padding-top: 55px;
  } */
  .home >>> .overlay .overlay__bg {
    @apply absolute inset-0 z-0 bg-black-plain;
    opacity: 0.35;
  }
  /*.home >>> .popup__main {*/
  /*  @apply bg-white rounded-xl*/
  /*}*/
  /*.home >>> .popup__content {*/
  /*  @apply h-full*/
  /*}*/
  .home__banners {
    @apply mt-4;
  }
  .home__side_section {
    @apply hidden;
  }
  .home__related-lists {
    @apply mb-2;
  }
}

@screen desktop {
  .home {
    margin-bottom: -46px;
  }
  .home__main {
    @apply mb-8;

    & .home-job-cate-menu {
      @apply hidden;
    }
  }
  .home__banners,
  .home__side_section {
    @apply mt-11;
  }
}

/* Hard code to media query to handle low resolution device */
/* Special handling for home page only. */
@media (min-width: 960px) and (max-width: 1199px) {
  .home {
    max-width: 960px;

    &::v-deep .banner__list-container {
      max-width: 590px;
    }
  }
  .home__featured-lists,
  .home__related-lists {
    &::v-deep .list__job {
      width: 50%;
    }
  }
  .home__feature-company-lists {
    &::v-deep .list__job {
      @apply w-1/3;
    }
  }
}

/* Hard code to media query to handle wide screen */
/* Special handling for home page only. */
@media (min-width: 1200px) {
  .home {
    max-width: 1200px;
  }
  .home__featured-lists,
  .home__related-lists {
    &::v-deep .list__job {
      width: 100/3%;
    }
  }
}
</style>
