<template>
  <section class="side-menu-item">
    <n-link class="side-menu-item__link" :title="title" :to="route" @click.native="onClick">
      <div class="side-menu-item__content">
        <component :is="`svg-${icon}`" class="side-menu-item__icon" />
        <p class="side-menu-item__text">
          {{ title }}
        </p>
      </div>
    </n-link>
  </section>
</template>

<script>
import { findJobTypesByJobCategory, getJobCategoryTrackingName } from '../../libs/mapping/jobType'
import { cleanStringForURL } from '../../libs/mapping'
import { findEmploymentTypeFieldByName } from '../../libs/mapping/employmentType'
import { getParamSearchPath } from '../../libs/mapping/searchParam'
import { NEARBY_L_KEY } from '../../libs/mapping/nearby'
import { sendHomeCategoryClickEvent } from '../../libs/tracking/utils/event'
import SVGBeauty from '../../assets/svg/jobcategory/Beauty.svg?inline'
import SVGCleaning from '../../assets/svg/jobcategory/Cleaning.svg?inline'
import SVGConstruction from '../../assets/svg/jobcategory/Construction.svg?inline'
import SVGCustomerSer from '../../assets/svg/jobcategory/CustomerSer.svg?inline'
import SVGEducation from '../../assets/svg/jobcategory/Education.svg?inline'
import SVGFnB from '../../assets/svg/jobcategory/F&B.svg?inline'
import SVGFullTime from '../../assets/svg/jobcategory/FullTime.svg?inline'
import SVGHealth from '../../assets/svg/jobcategory/Health.svg?inline'
import SVGHotel from '../../assets/svg/jobcategory/Hotel.svg?inline'
import SVGLogistic from '../../assets/svg/jobcategory/Logistic.svg?inline'
import SVGNearby from '../../assets/svg/jobcategory/Nearby.svg?inline'
import SVGOffice from '../../assets/svg/jobcategory/Office.svg?inline'
import SVGPartTime from '../../assets/svg/jobcategory/PartTime.svg?inline'
import SVGProduction from '../../assets/svg/jobcategory/Production.svg?inline'
import SVGPromotion from '../../assets/svg/jobcategory/Promotion.svg?inline'
import SVGPropertyMgt from '../../assets/svg/jobcategory/PropertyMgt.svg?inline'
import SVGRepair from '../../assets/svg/jobcategory/Repair.svg?inline'
import SVGRetail from '../../assets/svg/jobcategory/Retail.svg?inline'
import SVGSalesAgent from '../../assets/svg/jobcategory/Sales&Agent.svg?inline'
import SVGTemp from '../../assets/svg/jobcategory/Temp.svg?inline'
export default {
  components: {
    'svg-beauty-fitness': SVGBeauty,
    'svg-cleaning': SVGCleaning,
    'svg-construction': SVGConstruction,
    'svg-customer-services': SVGCustomerSer,
    'svg-education': SVGEducation,
    'svg-events-promotion': SVGPromotion,
    'svg-food-beverage': SVGFnB,
    'svg-fulltime': SVGFullTime,
    'svg-health-services': SVGHealth,
    'svg-hotel-club-house': SVGHotel,
    'svg-logistics-transport': SVGLogistic,
    'svg-maintenance': SVGRepair,
    'svg-nearby': SVGNearby,
    'svg-office': SVGOffice,
    'svg-parttime': SVGPartTime,
    'svg-production-packaging': SVGProduction,
    'svg-property-mgt-security': SVGPropertyMgt,
    'svg-retail-shop': SVGRetail,
    'svg-sales-agents': SVGSalesAgent,
    'svg-temp': SVGTemp,
  },
  computed: {
    /**
     * Menu item icon name,
     * based on category key
     */
    icon() {
      return cleanStringForURL(this.categoryKey)
    },
    /**
     * Route w/ search param,
     * based on category key
     */
    route() {
      // Special route for nearby search
      if (this.categoryKey === NEARBY_L_KEY) {
        return {
          path: this.localePathName('/find-jobs'),
          query: {
            source: this.eventSource,
            spk: this.categoryKey,
          },
        }
      }

      // Otherwise generate route based on
      return {
        path: this.localePathName(
          getParamSearchPath(
            {
              employment: [this.categoryKey],
              jobType: findJobTypesByJobCategory(this.categoryKey)
                .map((jt) => jt.shortCode)
                .filter((shortCode) => shortCode),
            },
            this.currentLocale.code,
          ),
        ),
        query: {
          source: this.eventSource,
        },
      }
    },
    /**
     * Search param title
     */
    title() {
      let prefix = 'jobCategory'
      if (findEmploymentTypeFieldByName(this.categoryKey, 'name')) {
        prefix = 'employment'
      }
      return this.$t(`${prefix}.${this.categoryKey}`)
    },
    type() {
      return (
        findEmploymentTypeFieldByName(this.categoryKey, 'trackingName') || getJobCategoryTrackingName(this.categoryKey)
      )
    },
  },
  methods: {
    onClick() {
      sendHomeCategoryClickEvent(this.$eventTracker, this.type)
    },
  },
  props: {
    categoryKey: {
      default: '',
      required: true,
      type: String,
    },
    /**
     * Used for event tracking
     */
    eventSource: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.side-menu-item {
  @apply flex;
  height: 36px;
}
.side-menu-item__link {
  @apply flex w-full h-full rounded no-underline items-center;
  &:hover,
  &:active {
    @apply bg-red-50;
  }
}
.side-menu-item__content {
  @apply flex flex-row items-center;
}
.side-menu-item__text {
  @apply ml-3 text-cbody2 font-cbody2;
}
.side-menu-item__icon {
  @apply flex-shrink-0 ml-2;
  width: 32px;
  height: 32px;
}
</style>
