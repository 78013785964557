<script>
import { getPageMetadata } from '../../libs/metadata'
export default {
  computed: {
    description() {
      return this.jobCount > 0
        ? this.$t('homepage.metaDescription', {
            numberOfJobs: this.jobCount,
          })
        : this.$t('metadata.description')
    },
  },
  head() {
    return getPageMetadata(this.$t('metadata.title'), this.description)
  },
  props: {
    jobCount: {
      default: null,
      type: Number,
    },
  },
  // Handle to empty single root
  render(createElement) {
    return null
  },
}
</script>
